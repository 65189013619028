<template>
  <div>
    <vue-pivottable-ui
      v-if="pivotData"
      :data="pivotData"
      :aggregator-name="aggregatorName"
      renderer-name="Table Heatmap"
      :row-total="true"
      :col-total="true"
      :rows="rows"
      :cols="cols"
      :vals="vals"
      :localeStrings="localeStrings['es']"
    >
    </vue-pivottable-ui>
  </div>
</template>

<script>
import { VuePivottableUi } from "vue-pivottable";

export default {
  components: {
    VuePivottableUi,
  },
  data() {
    return {
      localeStrings: {
        en: {
          renderError: "An error occurred rendering the PivotTable results.",
          computeError: "An error occurred computing the PivotTable results.",
          uiRenderError: "An error occurred rendering the PivotTable UI.",
          selectAll: "Select All",
          selectNone: "Select None",
          tooMany: "too many values to show",
          filterResults: "Filter values",
          totals: "Totals",
          only: "only",
          rendererNames: {
            Table: "Table",
            "Table Heatmap": "Table Heatmap",
            "Table Col Heatmap": "Table Col Heatmap",
            "Table Row Heatmap": "Table Row Heatmap",
            "Export Table TSV": "Export Table TSV",
            "Grouped Column Chart": "Grouped Column Chart",
            "Stacked Column Chart": "Stacked Column Chart",
            "Grouped Bar Chart": "Grouped Bar Chart",
            "Stacked Bar Chart": "Stacked Bar Chart",
            "Line Chart": "Line Chart",
            "Dot Chart": "Dot Chart",
            "Area Chart": "Area Chart",
            "Scatter Chart": "Scatter Chart",
            "Multiple Pie Chart": "Multiple Pie Chart",
          },
          aggregatorMap: {
            Count: "Count",
            "Count Unique Values": "Count Unique Values",
            "List Unique Values": "List Unique Values",
            Sum: "Sum",
            "Integer Sum": "Integer Sum",
            Average: "Average",
            Median: "Median",
            "Sample Variance": "Sample Variance",
            "Sample Standard Deviation": "Sample Standard Deviation",
            Minimum: "Minimum",
            Maximum: "Maximum",
            First: "First",
            Last: "Last",
            "Sum over Sum": "Sum over Sum",
            "Sum as Fraction of Total": "Sum as Fraction of Total",
            "Sum as Fraction of Rows": "Sum as Fraction of Rows",
            "Sum as Fraction of Columns": "Sum as Fraction of Columns",
            "Count as Fraction of Total": "Count as Fraction of Total",
            "Count as Fraction of Rows": "Count as Fraction of Rows",
            "Count as Fraction of Columns": "Count as Fraction of Columns",
          },
        },
        es: {
          renderError:
            "A ocurrido un Error al renderizar los resultados del Pivot Table.",
          computeError:
            "A ocurrido un Error al Procesar los resultados del Pivot Table.",
          uiRenderError:
            "A ocurrido un Error al renderizar los resultados del Pivot Table UI.",
          selectAll: "Seleccionar Todo",
          selectNone: "Quitar Todo",
          tooMany: "Muchos valores para mostrar",
          filterResults: "Filtrar Valores",
          totals: "Totales",
          only: "unico",
          rendererMap: {
            Table: "Tabla",
            "Table Heatmap": "Mapa de calor",
            "Table Col Heatmap": "Col Mapa de calor",
            "Table Row Heatmap": "Row Mapa de calor",
            "Export Table TSV": "Exportar Tabla TSV",
            "Grouped Column Chart": "Gráfico de columnas agrupadas",
            "Stacked Column Chart": "Gráfico de columnas apiladas",
            "Grouped Bar Chart": "Gráfico de barras agrupadas",
            "Stacked Bar Chart": "Gráfico de barras apiladas",
            "Line Chart": "Gráfico de lineas",
            "Dot Chart": "Gráfico de puntos",
            "Area Chart": "Gráfico de area",
            "Scatter Chart": "Gráfico de dispersión",
            "Multiple Pie Chart": "Gráfico Pie múltiple",
          },
          aggregatorMap: {
            Count: "Contar",
            "Count Unique Values": "Contar Valores Unicos",
            "List Unique Values": "Lista de Valores Unicos",
            Sum: "Suma",
            "Integer Sum": "Suma entera",
            Average: "Promedio",
            Median: "Media",
            "Sample Variance": "Varianza Simple",
            "Sample Standard Deviation": "Desviación estándar",
            Minimum: "Minimo",
            Maximum: "Maximo",
            First: "Primero",
            Last: "Ultimo",
            "Sum over Sum": "Suma sobre suma",
            "Sum as Fraction of Total": "Suma como fracción del total",
            "Sum as Fraction of Rows": "Suma como Fracción de Filas",
            "Sum as Fraction of Columns": "Suma como Fracción de Columnas",
            "Count as Fraction of Total": "Recuento como fracción del total",
            "Count as Fraction of Rows": "Recuento como fracción de Filas",
            "Count as Fraction of Columns":
              "Recuento como fracción de Columnas",
          },
        },
      },
    };
  },
  props: {
    pivotData: {
      type: Array,
    },
    aggregatorName: {
      type: String,
    },
    rendererName: {
      type: String,
    },
    rows: {
      type: Array,
    },
    cols: {
      type: Array,
    },
    vals: {
      type: Array,
    },
    disabledFromDragDrop: {
      type: Array,
    },
    hiddenFromDragDrop: {
      type: Array,
    },
  },
};
</script>
<style>
.pvtDropdown {
  max-width: 130px;
}

.pvtUi {
  table-layout: auto;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

table.pvtTable {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}
</style>